

import __layout_0 from '/frontend/src/layouts/default.vue'
export const layouts = {
'blank': () => import('/frontend/src/layouts/blank.vue'),
'default': __layout_0,
'components/DefaultLayoutWithVerticalNav': () => import('/frontend/src/layouts/components/DefaultLayoutWithVerticalNav.vue'),
'components/Footer': () => import('/frontend/src/layouts/components/Footer.vue'),
'components/NavBarI18n': () => import('/frontend/src/layouts/components/NavBarI18n.vue'),
'components/NavBarNotifications': () => import('/frontend/src/layouts/components/NavBarNotifications.vue'),
'components/NavbarAlerts': () => import('/frontend/src/layouts/components/NavbarAlerts.vue'),
'components/NavbarShortcuts': () => import('/frontend/src/layouts/components/NavbarShortcuts.vue'),
'components/NavbarThemeSwitcher': () => import('/frontend/src/layouts/components/NavbarThemeSwitcher.vue'),
'components/SearchBar': () => import('/frontend/src/layouts/components/SearchBar.vue'),
'components/SearchBarOverlay': () => import('/frontend/src/layouts/components/SearchBarOverlay.vue'),
'components/UserProfile': () => import('/frontend/src/layouts/components/UserProfile.vue'),
'components/UserProfileMobile': () => import('/frontend/src/layouts/components/UserProfileMobile.vue'),
}

export function setupLayouts(routes) {
  return routes.map(route => {
    return { 
      path: route.path,
      component: layouts[route.meta?.layout || 'default'],
      children: [ {...route, path: ''} ],
    }
  })
}
