import { defineStore } from "pinia"
import CuentasApi from "@/api/cuentas.api.js"

export const useRunaStore = defineStore('runaData', {
  state: () => ({
    loading: false,
    userData: null,
    userAbilities: [],
    companyConfig: {},
    siatConfig: {}
  }),
  actions: {
    restart() {
      this.loading = false
      this.userData = null
      this.userAbilities = []
      this.companyConfig = {}
      this.siatConfig = {}
    },
    async usuario() {
      const response = await CuentasApi.usuario()
      const loggedIn = response.autorizado && response.data
      this.userData = null
      if (loggedIn) {
        this.userData = response.data
        return true
      } else {
        return false
      }

    },
    async login(username, password) {
      this.loading = true
      const response = await CuentasApi.login(username, password)
      this.loading = false
      const loggedIn = response.autorizado && response.data
      this.userData = null
      if (loggedIn) {
        this.userData = response.data
        this.companyConfig = response.yupana
      }
      return response
    },
    async logout() {
      this.loading = true
      const response = await CuentasApi.logout()
      this.loading = false
      if (!response.autorizado) {
        this.userData = null
        return true
      }
      return false
    },
  },
})
