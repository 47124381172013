<script setup>
import { useThemeConfig } from '@core/composable/useThemeConfig'

const {
  syncInitialLoaderTheme,
  syncVuetifyThemeWithTheme: syncConfigThemeWithVuetifyTheme,
  isAppRtl,
} = useThemeConfig()

syncInitialLoaderTheme()
syncConfigThemeWithVuetifyTheme()

</script>

<template>
  <VLocaleProvider :rtl="isAppRtl">
    <VApp>
      <VMain>
        <RouterView />
      </VMain>
    </VApp>
  </VLocaleProvider>
</template>

<style>
@font-face {
  font-family: 'Bahiana';
  font-style: normal;
  src: local('Bahiana-Regular'), url(@/assets/fonts/Bahiana-Regular.ttf) format('truetype');
}
/**
@font-face {
  font-family: 'Public Sans';
  font-style: normal;
  src: local('PublicSans-VariableFont_wght'), url(@/assets/fonts/PublicSans-VariableFont_wght.ttf) format('truetype');
}

@font-face {
  font-family: 'Public Sans';
  font-style: italic;
  src: local('PublicSans-Italic-VariableFont_wght'), url(@/assets/fonts/PublicSans-Italic-VariableFont_wght.ttf) format('truetype');
} **/
</style>
