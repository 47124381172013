import { defineStore } from "pinia"
import yupanaApi from "@/api/yupana.api.js"
import api from "@/api/config.js"
import { useRunaStore } from './runaStore.js'
import { useModelStore } from './modelStore.js'
import { isProxy, toRaw } from 'vue'
import moment from 'moment'
// revisar
const colValues = {
	"sm": {
		"cols": 12,
		"xs": 12,
		"sm": 8,
		"md": 6,
		"lg": 4,
		"xl": 3
	},
	"md": {
		"cols": 12,
		"xs": 12,
		"sm": 12,
		"md": 8,
		"lg": 6,
		"xl": 6
	},
	"lg": {
		"cols": 12,
		"xs": 12,
		"sm": 12,
		"md": 12,
		"lg": 12,
		"xl": 12
	}
}
export const useYupanaStore = defineStore('yupana', {
	state: () => ({
		ready: false,
		siatOnline: true,
		datos: {},
		schema: {},
		data: null,
		dataList: [],
		uischema: {},
		meta: {},
		title: 'Documento',
		filteredDocs: [],
		searchQuery: null,
		products: [],
		cuentas: [],
		items: [],
		uoms: [],
		clients: [],
		proveedores: [],
		stock: [],
		additionalDiscount: 0,
		defaultEmail: '',
		fileFields: [],
		pos: [],
		currentPos: null,
		sucursales: null,
		useScanner: false,
		userList: [],
		userConfig: {},
		syncTime: null,
		currentLocations: {},
		siatConfig: null,
		cufdActual: null,
		btPrinter: false,
		openPos: [],
		pendientes: [],
		alertBar: {
			visible: false,
			color: 'error',
			text: ''
		},
	}),
	actions: {
		restart() {},
		async getList(modelo, filtros) {
			const datos = await yupanaApi.getList(modelo, filtros)
			this.datos[modelo] = datos
			return datos
		},
		async fetchClients() {
			const clientList = await yupanaApi.getList('cliente', {})
			this.datos['cliente'] = clientList
			this.clients = clientList
			return this.clients
		},
		async fetchProveedores() {
			const proveedorList = await yupanaApi.getList('proveedor', {})
			this.datos['proveedor'] = proveedorList
			this.proveedores = proveedorList
			return this.proveedores
		},
		async fetchItems() {
			const itemList = await yupanaApi.getList('item', {})
			this.datos['item'] = itemList
			this.items = itemList
			return this.items
		},
		async fetchCuentas() {
			const accountList = await yupanaApi.getList('cuenta', {})
			this.datos['cuenta'] = accountList
			this.cuentas = accountList
			return this.cuentas
		},
		async fetchProducts() {
			const productList = await yupanaApi.getList('producto', {})
			this.datos['producto'] = productList
			// productList.push({...c, qty: 0, discount: 0})
			this.products = productList
		},
		async fetchCompras() {
			const comprasList = await yupanaApi.getList('compra', {})
			this.datos['compra'] = comprasList
			return comprasList
		},
		fetchDoc(modelo, id, campo) {
			const doc = this.datos[modelo].find(item => item[campo] === id)
			this.data = doc
			return this.data
		},
		async nuevoDocumentoArchivo(modelo, datos, archivo, campo) {
			const response = await yupanaApi.nuevoDocumentoArchivo(modelo, datos, archivo, campo)
			if (response) {
				this.ready = true
			} else {
				this.ready = false
			}
			return response.data
		},
		async nuevoDocumento(modelo, datos) {
			const response = await yupanaApi.nuevoDocumento(modelo, datos)
			if (response) {
				this.ready = true
			} else {
				this.ready = false
			}
			return response.data
		},
		async actualizarDocumentoArchivo(modelo, nombre, datos, archivo, campo) {
			const response = await yupanaApi.actualizarDocumentoArchivo(modelo, nombre, datos, archivo, campo)
			if (response) {
				this.ready = true
			} else {
				this.ready = false
			}
			return response.data
		},
		async actualizarDocumento(modelo, nombre, datos) {
			const response = await yupanaApi.actualizarDocumento(modelo, nombre, datos)
			if (response) {
				this.ready = true
			} else {
				this.ready = false
			}
			return response.data
		},
		async anularFactura(cuf, motivo) {
			let data = await yupanaApi.anularFactura(cuf, motivo)
			return data
		},
		async borrarDocumento(modelo, id) {
			const docRef = await yupanaApi.borrarDocumento(modelo, id)
			this.alertBar = { visible: true, color: 'info', text: 'Documento borrado.' }
		},
		async getSiatSyncList() {
			const modelStore = useModelStore()
			const syncList = await yupanaApi.getList('sincronizacion', {})
			modelStore.addSiat(syncList);
		},
		async getSiatConfig() {
			const runaData = useRunaStore()
			const cuisList = await yupanaApi.getList('cuis', {})
			runaData.siatConfig = cuisList
			this.cufdActual = runaData.siatConfig.find(s => s.cuis == runaData.userData.cuis).cufdActual
			//      this.sucursales = runaData.siatConfig.pdv
			return cuisList
		},
		async syncSiat() {
			let data = await yupanaApi.syncSiat()
			if (data) {
				this.alertBar = {
					visible: true,
					color: 'info',
					text: 'CUFD Sincronizado'
				}
			} else {
				this.alertBar = {
					visible: true,
					color: 'error',
					text: 'Error de Sincronización CUFD'
				}
			}
		},
		async isSiatOnline() {
			if (!navigator.onLine) {
				this.alertBar = {
					visible: true,
					color: 'error',
					text: "No hay conexión con el sistema SIAT.  Se procederá en modo fuera de línea."
				}
				this.siatOnline = false
				return this.siatOnline
			}
			try {
				let response = await yupanaApi.isSiatOnline()
				if (response?.transaccion == true) {
					this.siatOnline = true

					if (this.pendientes.length > 0) {
						this.pendientes.forEach(async (config) => {
							try {
								const response = await api.request(config);
								console.log('Request successful:', response.data);
								this.pendientes.splice(this.pendientes.indexOf(config), 1);
							} catch (error) {
								console.error('Error resending request:', error);
							}
						});
					}
				} else {
					this.alertBar = {
						visible: true,
						color: 'error',
						text: "No hay conexión con el sistema SIAT.  Se procederá en modo fuera de línea."
					}
					this.siatOnline = false
				}
			} catch (e) {
				this.alertBar = {
					visible: true,
					color: 'error',
					text: "No hay conexión con el sistema SIAT.  Se procederá en modo fuera de línea."
				}
				this.siatOnline = false
			}
			return this.siatOnline
		},
		async getSiatSync() {
			try {
				let response = await yupanaApi.getSiatSync()
				if (response?.respuesta == "Sincronizado") {
					this.alertBar = {
						visible: true,
						color: 'success',
						text: "Sincronización exitosa con el sistema SIAT."
					}
					this.siatOnline = true
				} else {
					this.alertBar = {
						visible: true,
						color: 'error',
						text: "No hay conexión con el sistema SIAT.  Se procederá en modo fuera de línea."
					}
					this.siatOnline = false
				}
				return response
			} catch (e) {
				this.alertBar = {
					visible: true,
					color: 'error',
					text: "No hay conexión con el sistema SIAT.  Se procederá en modo fuera de línea."
				}
				this.siatOnline = false
				return false
			}
		},
		async updateSiatConfig(cuis, numeracion) {
			try {
				let data = await yupanaApi.actualizarCuis(cuis, numeracion)
				this.alertBar = {
					visible: true,
					color: 'success',
					text: "Numeración actualizada."
				}
				return data
			} catch (e) {
				this.alertBar = {
					visible: true,
					color: 'warning',
					text: "Esta opción no está habilitada."
				}
			}
		},
		async generateFactura(siatData) {
			let data = await yupanaApi.generateFactura(siatData)
			return data
		},
		async generarCompra(compraData) {
			let data = await yupanaApi.generarCompra(compraData)
			return data
		},
		async generarEvento(eventoData) {
			let data = await yupanaApi.generarEvento(eventoData)
			return data
		},
		async procesarEvento(evento) {
			let data = await yupanaApi.procesarEvento(evento)
			return data
		},
		async getContable(enlace) {
			let data = await yupanaApi.getContable(enlace)
			return data
		},
		async verifySiatNit(clientNit) {
			let data = await yupanaApi.verifySiatNit(clientNit)
			if (data) {
				return data
			} else {
				return false
			}
		}
	},
	persist: {
		storage: localStorage
	}
})
