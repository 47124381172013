import '@/@iconify/icons-bundle'
import App from '@/App.vue'
import i18n from '@/plugins/i18n'
import layoutsPlugin from '@/plugins/layouts'
import vuetify from '@/plugins/vuetify'
import router from '@/router'
import '@/styles/styles.scss'
import '@core/scss/template/index.scss'
import { createPinia } from 'pinia'
import { createApp } from 'vue'
import VMask from "@ssibrahimbas/v-mask"
import piniaPluginPersistedState from "pinia-plugin-persistedstate"
import { storePlugin } from 'pinia-plugin-store'
import AES from 'crypto-js/aes'
import Utf8 from 'crypto-js/enc-utf8'
import Base64 from 'crypto-js/enc-base64'
import { JsonForms } from '@jsonforms/vue'
import { localeCurrency } from '@/plugins/localeCurrency'
import { VueMasonryPlugin } from "vue-masonry"
import { loadFonts } from '@/plugins/webfontloader'
import VueHtmlToPaper from 'vue-html-to-paper';
import { environment } from "@/settings"



loadFonts()

const storePassword = "XWjbRTnbGFaVLxgvVJerq3uzrXtMHr"

function encrypt(value) {
  return AES.encrypt(JSON.stringify(value), storePassword)
}

function decrypt(value) {
  return JSON.parse((AES.decrypt(value, storePassword)).toString(Utf8));
}
const piniaPluginEncrypt = storePlugin({
  stores: [{ name: 'runaData' }],
  storage: localStorage,
  encrypt,
  decrypt,
})
const pinia = createPinia();
pinia.use(piniaPluginPersistedState)
pinia.use(piniaPluginEncrypt)
const app = createApp(App)
app.use(vuetify)
app.use(pinia)
app.use(layoutsPlugin)
app.use(i18n)
app.use(JsonForms)
app.use(VMask)
app.use(VueMasonryPlugin)
app.use(VueHtmlToPaper)
app.provide('localeCurrency', localeCurrency)
app.use(router)

app.mount('#app')
