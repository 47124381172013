export function localeCurrency()  {
	const currencyBOB = (total) => {
			return total.toLocaleString('es-BO', {minimumFractionDigits:2, maximumFractionDigits:2})
	};

	return {
		currencyBOB
	}

}
