<script setup>
import { PerfectScrollbar } from 'vue3-perfect-scrollbar'
import { VNodeRenderer } from './VNodeRenderer'
import {
  injectionKeyIsVerticalNavHovered,
  useLayouts,
} from '@layouts'
import {
  VerticalNavGroup,
  VerticalNavLink,
  VerticalNavSectionTitle,
} from '@layouts/components'
import { useYupanaStore } from '@/stores/yupanaStore'
import { config } from '@layouts/config'
import NavbarThemeSwitcher from '@/layouts/components/NavbarThemeSwitcher.vue'
import { useRunaStore } from '@/stores/runaStore'
const router = useRouter()
const runaStore = useRunaStore()
const store = useYupanaStore()
const logout = () => {
//  store.signOut()
  runaStore.logout()
//  store.restart()
  router.push('/login').then(() => {

  })
}

const verListaPendiente = () => {
//  router.push('/pendientes')
}

const props = defineProps({
  tag: {
    type: [
      String,
      null,
    ],
    required: false,
    default: 'aside',
  },
  navItems: {
    type: null,
    required: true,
  },
  isOverlayNavActive: {
    type: Boolean,
    required: true,
  },
  toggleIsOverlayNavActive: {
    type: Function,
    required: true,
  },
})
const refNav = ref()
const { width: windowWidth } = useWindowSize()
const isHovered = useElementHover(refNav)
provide(injectionKeyIsVerticalNavHovered, isHovered)
const {
  isVerticalNavCollapsed: isCollapsed,
  isLessThanOverlayNavBreakpoint,
  isVerticalNavMini,
  isAppRtl,
} = useLayouts()
const hideTitleAndIcon = isVerticalNavMini(windowWidth, isHovered)
const resolveNavItemComponent = item => {
  if ('heading' in item) return VerticalNavSectionTitle
  if ('children' in item) return VerticalNavGroup
  return VerticalNavLink
}
const route = useRoute()
watch(() => route.name, () => {
  props.toggleIsOverlayNavActive(false)
})
const isVerticalNavScrolled = ref(false)
const updateIsVerticalNavScrolled = val => isVerticalNavScrolled.value = val
const handleNavScroll = evt => {
  isVerticalNavScrolled.value = evt.target.scrollTop > 0
}

const isOnline = useOnline()

const refreshSiat = async () => {
  await store.isSiatOnline();
  await store.getSiatConfig();
}


import logoSm from '@/assets/logoSm.svg?raw'
const logoNode = h('div', {
  innerHTML: logoSm,
  class: 'logo-nav-yupana',
  style: 'line-height:0;'
})

/**
const isOnline = () => {
  if ('connection' in navigator && navigator.onLine) return true
  return false
}
**/
const reloadPage = () => {
  store.ready = true
  location.reload()
}
if (!store.ready) {
  reloadPage()
}


</script>

<template>
<Component :is="props.tag" ref="refNav" class="layout-vertical-nav" :class="[
      {
        'overlay-nav': isLessThanOverlayNavBreakpoint(windowWidth),
        'hovered': isHovered,
        'visible': isOverlayNavActive,
        'scrolled': isVerticalNavScrolled,
      },
    ]" style="background-color:#7367F0;color:#fff;">
  <!-- 👉 Header -->
  <div class="nav-header">
    <slot name="nav-header">
      <RouterLink to="/" class="app-logo d-flex align-center gap-x-3 app-title-wrapper">
        <VNodeRenderer :nodes="logoNode" />
        <Transition name="vertical-nav-app-title">
          <h1 v-show="!hideTitleAndIcon" class="font-weight-bold leading-normal text-l text-white" style="font-family: 'Bahiana', sans-serif;font-size:46px;">
            {{ config.app.title }}
          </h1>
        </Transition>
      </RouterLink>
      <!-- 👉 Vertical nav actions -->
      <!-- Show toggle collapsible in >md and close button in <md -->
      <template v-if="!isLessThanOverlayNavBreakpoint(windowWidth)">
        <Component :is="config.app.iconRenderer || 'div'" v-show="isCollapsed && !hideTitleAndIcon" class="header-action" v-bind="config.icons.verticalNavUnPinned" @click="isCollapsed = !isCollapsed" />
        <Component :is="config.app.iconRenderer || 'div'" v-show="!isCollapsed && !hideTitleAndIcon" class="header-action" v-bind="config.icons.verticalNavPinned" @click="isCollapsed = !isCollapsed" />
      </template>
      <template v-else>
        <Component :is="config.app.iconRenderer || 'div'" class="header-action" v-bind="config.icons.close" @click="toggleIsOverlayNavActive(false)" />
      </template>
    </slot>
  </div>
  <slot name="before-nav-items">
    <div class="vertical-nav-items-shadow" />
  </slot>
  <slot name="nav-items" :update-is-vertical-nav-scrolled="updateIsVerticalNavScrolled">
    <PerfectScrollbar :key="isAppRtl" tag="ul" class="nav-items" :options="{ wheelPropagation: false }" @ps-scroll-y="handleNavScroll">
    <VList class="py-2 mb-3" style="background-color:#7367F0; color: white">
      <!--    <VListItem>
          <template #prepend>
            <VListItemAction start>
              <VBadge dot location="bottom right" offset-x="3" offset-y="3" :color="isOnline == true ? 'success' : 'error'">
                <VAvatar color="primary" variant="tonal">
                  <VImg v-if="runaStore.userData && runaStore.userData.avatar" :src="runaStore.userData.avatar" />
                  <VIcon v-else icon="mdi-account-outline" />
                </VAvatar>
              </VBadge>
            </VListItemAction>
          </template>
          <VListItemTitle class="font-weight-semibold" v-show="!isCollapsed  || !hideTitleAndIcon">
            {{ runaStore.userData.fullName }}
          </VListItemTitle>
          <VListItemSubtitle v-show="!isCollapsed  || !hideTitleAndIcon">{{ runaStore.userData.role }}</VListItemSubtitle>
          <VListItemTitle class="font-weight-semibold" v-show="!isCollapsed  || !hideTitleAndIcon">
            {{ store.company }}
          </VListItemTitle>
        </VListItem> -->
        <VListItem density="compact">
          <VBtn v-if="!store.ready && isOnline == true" @click="reloadPage()" color="info" block>
            <VIcon icon="mdi-refresh" /><span v-show="!isCollapsed  || !hideTitleAndIcon">Sincronizar</span>
          </VBtn>
          <VBtn v-else-if="isOnline == false || !store.siatOnline" @click="verListaPendiente()" color="warning" block>
            <VIcon size="14" icon="mdi-wifi-off" />
            <span v-if="!isCollapsed  || !hideTitleAndIcon">Fuera de línea</span>
          </VBtn>
          <VBtn v-else color="success" block>
            <VIcon icon="mdi-wifi" />
          <span v-show="!isCollapsed  || !hideTitleAndIcon">En línea</span>
          </VBtn>
        </VListItem>
        <VListItem density="compact">
        Vigencia CUFD: {{ store.cufdActual.fechaVigencia.slice(0,16) }} <br />
        </VListItem>
      </VList>
      <Component :is="resolveNavItemComponent(item)" v-for="(item, index) in navItems" :key="index" :item="item" />
    </PerfectScrollbar>
    <VListItem link @click="logout">
      <template #prepend>
        <VIcon class="ml-3 me-3" icon="mdi-logout" size="25" color="white" />
      </template>
      <VListItemTitle v-show="!isCollapsed || !hideTitleAndIcon">Cerrar Sesión</VListItemTitle>
    </VListItem>
  </slot>

</Component>
</template>

<style lang="scss">
@use "@configured-variables" as variables;
@use "@layouts/styles/mixins";

.logo-nav-yupana > svg {
    height: 28px;
}
// 👉 Vertical Nav
.layout-vertical-nav {
    position: fixed;
    z-index: variables.$layout-vertical-nav-z-index;
    display: flex;
    flex-direction: column;
    block-size: 100%;
    inline-size: variables.$layout-vertical-nav-width;
    inset-block-start: 0;
    inset-inline-start: 0;
    transition: transform 0.25s ease-in-out, inline-size 0.25s ease-in-out, box-shadow 0.25s ease-in-out;
    will-change: transform, inline-size;

    .nav-header {
        display: flex;
        align-items: center;

        .header-action {
            cursor: pointer;
        }
    }

    .app-title-wrapper {
        margin-inline-end: auto;
    }

    .nav-items {
        block-size: 100%;

        // ℹ️ We no loner needs this overflow styles as perfect scrollbar applies it
        // overflow-x: hidden;

        // // ℹ️ We used `overflow-y` instead of `overflow` to mitigate overflow x. Revert back if any issue found.
        // overflow-y: auto;
    }

    .nav-item-title {
        overflow: hidden;
        margin-inline-end: auto;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    // 👉 Collapsed
    .layout-vertical-nav-collapsed & {
        &:not(.hovered) {
            inline-size: variables.$layout-vertical-nav-collapsed-width;
        }
    }

    // 👉 Overlay nav
    &.overlay-nav {
        &:not(.visible) {
            transform: translateX(-#{variables.$layout-vertical-nav-width});

            @include mixins.rtl {
                transform: translateX(variables.$layout-vertical-nav-width);
            }
        }
    }
}
</style>
