import axios from "axios"
import { useYupanaStore } from "@/stores/yupanaStore"
import router from "../router"
const api = axios.create({
	baseURL: import.meta.env.VITE_API_BASE_URL,
	xsrfHeaderName: "X-CSRFToken",
	xsrfCookieName: "csrftoken",
	withCredentials: true,
})
export function responseSuccess(response) {
	return response
}

export function responseError(error) {
	const yupanaStore = useYupanaStore()
	if (!error.response && error.message === "Network Error") {
		yupanaStore.alertBar = {
			visible: true,
			color: 'error',
			text: 'Error de conexión.'
		}
		yupanaStore.pendientes.push(error.config)
	}
	if (error.response && error.response.status === 401) {
		yupanaStore.alertBar = {
			visible: true,
			color: 'error',
			text: "Usuario no autenticado."
		}
		router.push({
			name: "login",
		})
		return
	}
	return Promise.reject(error)
}
api.interceptors.response.use(responseSuccess, responseError)
export default api
