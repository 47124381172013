<script setup>
import {
  useYupanaStore
} from '@/stores/yupanaStore'

const store = useYupanaStore()
</script>

<template>
  <VSnackbar
  v-model="store.alertBar.visible"
  :color="store.alertBar.color"
  timeout="20000"
  location="top end"
>
{{ store.alertBar.text }}

<template #actions>
  <VBtn
    color="secondary"
    @click="store.alertBar.visible = false"
  >
    <VIcon icon="mdi-close" color="white" />
  </VBtn>
</template>
</VSnackbar>
</template>
