<script setup>
// import navItems from '@/navigation/vertical'
import { useRunaStore } from '@/stores/runaStore'
// Composable
import {
  useSkins
} from '@core/composable/useSkins'
import {   useThemeConfig } from '@core/composable/useThemeConfig'

// Components
import Footer from '@/layouts/components/Footer.vue'
import NavBarI18n from '@/layouts/components/NavBarI18n.vue'
import NavBarNotifications from '@/layouts/components/NavBarNotifications.vue'
import NavbarShortcuts from '@/layouts/components/NavbarShortcuts.vue'
import NavbarAlerts from '@/layouts/components/NavbarAlerts.vue'
//import NavbarThemeSwitcher from '@/layouts/components/NavbarThemeSwitcher.vue'
import UserProfileMobile from '@/layouts/components/UserProfileMobile.vue'
import UserProfile from '@/layouts/components/UserProfile.vue'
import SearchBar from '@/layouts/components/SearchBar.vue'
// import Chat from '@/layouts/components/Chat.vue'
import SearchBarOverlay from '@/layouts/components/SearchBarOverlay.vue'
import { PerfectScrollbar } from 'vue3-perfect-scrollbar'
// @layouts plugin
import {
  VerticalNavLayout
} from '@layouts'
import { config } from '@layouts/config'
import { VNodeRenderer } from '@layouts/components/VNodeRenderer'

const {
  appRouteTransition,
  isLessThanOverlayNavBreakpoint
} = useThemeConfig()
const {
  width: windowWidth
} = useWindowSize()
const {
  layoutAttrs,
  injectSkinClasses
} = useSkins()
import {
  themeConfig
} from '@themeConfig'
import logoSm from '@/assets/logoSm.svg?raw'
const logoNode = h('div', {
  innerHTML: logoSm,
  class: 'logo-nav-yupana',
  style: 'line-height:0;'
})
// ℹ️ This will inject classes in body tag for accurate styling
injectSkinClasses()

const route = useRoute()

const runaStore = useRunaStore()

const navItems = runaStore.companyConfig.navegacion

const showSearch = ref(false)

watch(route, () => {
  if (route.meta?.show_search) {
    showSearch.value = true
  }
}, {deep: true})

</script>

<template>
<VerticalNavLayout :nav-items="navItems" v-bind="layoutAttrs">
  <template #navbar="{ toggleVerticalOverlayNavActive }">

    <div class="d-flex h-100 align-center" style="background-color: white;margin-left:-16px; padding-left:16px" v-if="showSearch == true">
    <SearchBar
    @closeSearch="showSearch = false"
    />
  </div>

    <div class="d-flex h-100 align-center" v-else >


      <VNodeRenderer  v-if="isLessThanOverlayNavBreakpoint(windowWidth)" :nodes="logoNode" @click="toggleVerticalOverlayNavActive(true)"   />


      <h3 class="text-white mx-2 w-50 font-weight-light">
        {{ route.meta.nav_title }}
      </h3>
        <!--   <VBtn
          icon
          variant="text"
          color="default"
          class="ms-lg-n3"
          size="small"
        >
          <VIcon
            icon="mdi-magnify"
            size="24"
          />
        </VBtn> -->

        <VSpacer />

        <VBtn
        variant="tonal"
        color="primary"
        size="small"
        icon
          style="z-index: 1001;"
          @click="showSearch = !showSearch"
          class="mx-2"
        >
          <VIcon icon="mdi-magnify"  />
        </VBtn>

      <!--  <NavbarShortcuts />

      <Chat :isNavDrawerOpen="isChatActive" /> -->

  <!--

      <UserProfile class="mx-2" />


   <NavBarI18n />
        <NavbarThemeSwitcher />

        <NavBarNotifications class="me-2" />
         -->
    </div>


  </template>
  <!-- 👉 Pages -->
<template #content-loading>
  <div class="text-center">
    <VProgressCircular
      :size="50"
      color="primary"
      indeterminate
    />
  </div>
</template>


  <NavbarAlerts />
  <RouterView v-slot="{ Component }">
    <Component :is="Component" />
  </RouterView>





  <template #footer="{ toggleVerticalOverlayNavActive }">
  <!--  <VRow v-if="isLessThanOverlayNavBreakpoint(windowWidth)" justify="center" >
      <VCol cols="4" @click="toggleVerticalOverlayNavActive(true)" class="py-2 text-center " >
          <VNodeRenderer :nodes="logoNode" />
        </VCol>
        <VCol cols="4" class="py-2 text-center " @click="$router.push('/')" >
          <VIcon size="x-large" color="white" icon="mdi-home" />
        </VCol>
        <VCol cols="4" class="py-2 text-center " @click="toggleIsChatActive()" >
          <VIcon size="x-large" color="white" icon="mdi-message-text-outline" />
        </VCol>
      </VRow> -->
  </template>

  <!-- 👉 Customizer
    <TheCustomizer />  -->
</VerticalNavLayout>
</template>
<style>
.ps {
  height: 80vh;
}

.logo-nav-yupana > svg {
    height: 28px;
}

header.layout-navbar {
  background-image: linear-gradient(to right, #7367F0 , white);
}
</style>
