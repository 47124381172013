import api from "./config.js"
import apiHelpers from "./helpers.js"

export default {
  usuario: async () => {
    const response = await api.get("/api/cuentas/usuario")
    return response.data
  },
  login: async (username, password) => {
    const response = await api.post(
      "/api/cuentas/login",
      apiHelpers.dataToForm({ username, password })
    )
    return response.data
  },
  logout: async () => {
    const response = await api.post("/api/cuentas/logout")
    return response.data
  },
}
