<script setup>
import { PerfectScrollbar } from "vue3-perfect-scrollbar";

import searchAndBookmarkData from "../search-and-bookmark-data";

import { title } from "@core/utils/filter";

import { useYupanaStore } from "@/stores/yupanaStore";
const emit = defineEmits(['closeSearch'])

const filteredData = ref();
const search = ref();
const currentSelected = ref();
const store = useYupanaStore();

const pages = ref([]);
const route = useRoute();
const router = useRouter();

const closeSearchBar = () => {
  search.value = '';
  searchFrappe()
  emit('closeSearch');

}

const searchFrappe =  () => {
    store.searchQuery = search.value;

};

const suggestionSelected = (grp, suggestion) => {
  router.push(suggestion.route)

}
</script>

  <template v-if="!route.meta.no_search">





    <div style="width: 100%">
      <VTextField
        v-model="search"
        :placeholder="
          route.meta.search
            ? 'Buscar  ' + route.meta.title
            : '¿Qué quieres buscar?'
        "
        @input="searchFrappe"
        autocomplete="off"
        variant="plain"
      >
        <template #prepend-inner>
          <VIcon size="22" icon="mdi-magnify" />
        </template>

        <template #append-inner>
          <VBtn
            variant="text"
            color="default"
            icon
            size="x-small"
            @click="closeSearchBar"
          >
            <VIcon size="18" icon="mdi-close" class="text-medium-emphasis" />
          </VBtn>
        </template>
      </VTextField>

      <VMenu activator="parent" offset="14px" v-if="!route.meta.search">
        <VCard max-height="560" class="d-flex flex-column">
          <PerfectScrollbar
            tag="ul"
            class="chat-contacts-list px-3 py-2"
            :options="{ wheelPropagation: true }"
          >
            <li
              v-for="(suggestion_list, grp_name, grp_index) in filteredData"
              :key="grp_index"
              class="suggestions-groups-list"
            >
              <!-- Group Header -->
              <p class="suggestion-group-title">
                <span
                  ><b>
                    {{ title(grp_name) }}
                  </b>
                </span>
              </p>

              <!-- Suggestion List of each group -->
              <ul>

                <li
                  v-for="(suggestion, index) in suggestion_list"
                  :key="index"
                  class="suggestion-group-suggestion cursor-pointer"
                  :class="{
                    'suggestion-current-selected':
                      currentSelected === `${grp_index}.${index}`
                  }"
                  @mouseenter="currentSelected = `${grp_index}.${index}`"
                  @mousedown.prevent="suggestionSelected(grp_name, suggestion)"
                >
                  <RouterLink v-if="grp_name === 'paginas'" class="p-0">
                    <VIcon :icon="mdi-lightbulb-outline" class="mr-75" />
                    <span class="align-middle">{{ suggestion.name }}</span>
                  </RouterLink>
                  <template v-else-if="grp_name === 'documentos'">
                    <div class="d-flex align-items-center">
                      <VIcon icon="mdi-file-outline" class="mr-75" />
                      <div>
                        <p>{{ suggestion.name }}</p>
                        <small> {{ suggestion.doctype }}</small>
                      </div>
                      <small class="ml-auto">{{ suggestion.size }}</small>
                    </div>
                  </template>
                  <template v-else-if="grp_name === 'contactos'">
                    <div class="d-flex align-items-center">
                      <VIcon icon="mdi-account-outline" class="mr-75" />
                      <div>
                        <p>{{ suggestion.name }}</p>
                        <small>{{ suggestion.email }}</small>
                      </div>
                      <small class="ml-auto">{{ suggestion.time }}</small>
                    </div>
                  </template>
                </li>

                <li
                  v-if="!suggestion_list.length && search"
                  class="suggestion-group-suggestion no-results"
                >
                  <p>Sin resultados.</p>
                </li>
              </ul>
            </li>
          </PerfectScrollbar>
        </VCard>
      </VMenu>
    </div>
  </template>
