import { Icon } from '@iconify/vue'

const aliases = {
  collapse: 'mdi-chevron-up',
  complete: 'mdi-check',
  cancel: 'mdi-close',
  close: 'mdi-close',
  delete: 'mdi-close',
  clear: 'mdi-close',
  success: 'mdi-circle-outline-check',
  info: 'mdi-information-outline',
  warning: 'mdi-alert-circle-outline',
  error: 'mdi-close',
  prev: 'mdi-chevron-left',
  next: 'mdi-chevron-right',
  checkboxOn: 'mdi-checkbox-outline',
  checkboxOff: 'mdi-checkbox-blank-outline',
  checkboxIndeterminate: 'mdi-checkbox-intermediate',
  delimiter: 'mdi-circle-outline',
  sort: 'mdi-arrow-up',
  expand: 'mdi-chevron-down',
  menu: 'mdi-menu',
  subgroup: 'mdi-menu-down-outline',
  dropdown: 'mdi-chevron-down',
  radioOn: 'custom-checked-radio',
  radioOff: 'custom-unchecked-radio',
  edit: 'mdi-pencil-outline',
  ratingEmpty: 'custom-star-empty',
  ratingFull: 'custom-star-fill',
  ratingHalf: 'custom-star-half',
  loading: 'mdi-refresh',
  first: 'mdi-skip-previous-outline',
  last: 'mdi-skip-next-outline',
  unfold: 'mdi-arrow-up-down',
  file: 'mdi-paperclip',
  plus: 'mdi-plus',
  minus: 'mdi-minus',
}

export const iconify = {
  component: props => h(Icon, props),
}
export const icons = {
  defaultSet: 'iconify',
  aliases,
  sets: {
    iconify,
  },
}
