import { createRouter, createWebHistory } from "vue-router"
import { setupLayouts } from 'virtual:generated-layouts'
import { useYupanaStore } from '@/stores/yupanaStore'
import routes from '~pages'


const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      redirect: to => {
        return { name: 'inicio' }
      },
    },
    ...setupLayouts(routes),
  ],
  scrollBehavior() {
    return { top: 0 }
  },
})

router.beforeEach((to) => {
  const store = useYupanaStore()
  store.isSiatOnline().then(r => {
  })
  store.searchQuery = ''
  store.alertBar = {
    visible: false,
    color: 'error',
    text: ''
  }

})
export default router
