import api from "./config.js"
import apiHelpers from "./helpers.js"

const procesarFiltros = (filtros) => {
	let queryString = '';
	for (const [key, value] of Object.entries(filtros)) {
		if (queryString !== '') {
			queryString += '&';
		}
		queryString += `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
	}
	return queryString;
}
export default {
	getList: async (modelo, filtros) => {
		const response = await api.get(`/api/${modelo}?${procesarFiltros(filtros)}`)
		return response.data
	},
	syncSiat: async () => {
		const response = await api.get(`/api/sincronizacion/cufd`)
		return response.data
	},
	getSiatSync: async () => {
		const response = await api.get(`/api/sincronizacion`)
		return response.data
	},
	isSiatOnline: async () => {
		const response = await api.get(`/api/verificar/comunicacion`)
		return response.data
	},
	verifySiatNit: async (clientNit) => {
		const response = await api.get(`/api/verificar/nit/${clientNit}`)
		return response.data
	},
	generateFactura: async (siatData) => {
		const response = await api.post("/api/factura", siatData)
		return response.data
	},
	actualizarCuis: async (cuis, numeracion) => {
		const response = await api.put(`/api/cuis/${cuis}`, {numeracion: JSON.stringify(numeracion)})
		return response.data
	},
	nuevoDocumento: async (modelo, datos) => {
		const response = await api.post(`/api/${modelo}`, datos)
		return response.data
	},
	nuevoDocumentoArchivo: async (modelo, datos, archivo = null, campo = null) => {
		const formData = apiHelpers.dataToForm(datos)
		if (campo !== null) {
				formData.append(campo, archivo, archivo.name)
		}
		const response = await api.post(`/api/${modelo}`, formData)
		return response.data
	},
	actualizarDocumentoArchivo: async (modelo, nombre, datos, archivo = null, campo = null) => {
		const formData = apiHelpers.dataToForm(datos)
		if (campo !== null) {
				formData.append(campo, archivo, archivo.name)
		}
		const response = await api.put(`/api/${modelo}/${nombre}`, formData)
		return response.data
	},
	actualizarDocumento: async (modelo, nombre, datos) => {
		const response = await api.put(`/api/${modelo}/${nombre}`, datos)
		return response.data
	},
  borrarDocumento: async (modelo, id) => {
    const response = await api.delete(`/api/${modelo}/${id}`)
    return response.data
  },
  anularFactura: async (cuf, motivo) => {
    const response = await api.delete(`/api/factura/${cuf}/${motivo}`)
    return response.data
  },
	generarEvento: async (evento) => {
		const response = await api.post(`/api/evento`, evento)
		return response.data
	},
	generarCompra: async (compra) => {
		const response = await api.post(`/api/compra`, compra)
		return response.data
	},
	procesarEvento: async (evento) => {
		const response = await api.post(`/api/evento/${evento}`)
		return response.data
	},
	getContable: async (enlace) => {
		const response = await api.get(`/api/contabilidad/${enlace}`)
		return response.data
	}
}
