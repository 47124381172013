export const useModelStore = defineStore('modelData', {
	state: () => ({
		siat: {}
	}),
	actions: {
		addSiat(siatData) {
			siatData.forEach((item, i) => {
				this.siat[item.nombre] = item.contenido				
			});

		},
		getSiat(sincronizacion) {
			return this.siat[sincronizacion]
		},
	},
	persist: {
		storage: sessionStorage
	}
})
